<template>
  <div>
    <clini-profile-component @update-title="changeTitle" />
  </div>
</template>

<script>
import CliniProfileComponent from "@/components/hospitals-clinics/ClinicProfileComponent";

export default {
  name: "ClinicProfile",
  title: "Clinic Profile | Baja California Health Tourism",
  components: { CliniProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
